.component-set-overall-goal-modal {

  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-modal-content {
    background-color: #f4f6fa;
    @media (max-width: 767px) {
      background-color: white;
    }
  }
  .ant-modal-close {
    top: 22px;
    right: 22px;
  }
  .ant-modal-close-x{
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #1d1e2b;
  }
}

.cr-datepicker-dropdown {
  width: 350px;

  .ant-picker-date-panel {
    width: 100%;
  }

  .ant-picker-date-panel .ant-picker-content {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .ant-modal-centered .ant-modal {
    width: 100%!important;
    height: 100%;
    max-width: none;
    margin-top: 0;
  }
  .ant-modal-content{
    border-radius: 0;
    height: 100%;
  }
  .ant-modal-close {
    left: 22px;
  }
  .ant-modal{
    margin: 0;
    padding-bottom: 0;
  }
}

@primary-color: #464ac9;@border-radius-base: 4px;@error-color: #dc5758;