.ant-spin-nested-loading {
  width: 100%;
}

.ant-modal-confirm-btns {
  display: none;
}

.ant-menu:not(.ant-menu-horizontal),
.cr-dropdown-menu {
  .ant-menu-item-selected {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.85);
  }

  .cr-selected {
    background-color: #f0f3ff;
    color: #464ac9;
  }
}
.target-level {
  .ant-select-item-option-content {
    white-space: normal;
  }
}