.component-set-start-point-modal {
  padding: 0;
  border-radius: 12px;

  .ant-modal-close {
    top: 22px;
    right: 22px;

    .ant-modal-close-x {
      width: 20px;
      height: 20px;
      line-height: 20px;
      color: #1d1e2b;
    }
  }
}
@primary-color: #464ac9;@border-radius-base: 4px;@error-color: #dc5758;