.ant-select {
  .ant-select-selector {
    border: 1px solid #e3e7ed !important;
  }
}

.ant-select-single {
  .ant-select-selector {
    height: 48px !important;
    padding: 10px 20px !important;
  }
}

.ant-select-arrow{
  top: 46%;
  right: 20px;
}

.ant-select-item {
  padding-left: 20px;
}
@primary-color: #464ac9;@border-radius-base: 4px;@error-color: #dc5758;